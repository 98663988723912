// Packages
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

// Components
import UI from '../../shared/Components/UI/js';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import Profile from './Profile';

// Images
import phLogo from '../../shared/assets/images/logo/ph_logo.png';

// CSS
import './MainHeader.scss';

// Variables
const active_color = '#2990D5';
const inactive_color = 'transparent';

const flags = {
  php: phLogo,
};

export default class MainHeaderWeb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFlag: 'php',
      currency: 'PHP',
    };
  }

  // Function to go to login
  login = () => {
    UI.goTo('/login');
  };

  checkFlag = () => {
    if (this.state.currency === 'PHP') {
      return flags.php;
    }
  };

  changeCurrency = (name) => {
    this.setState({ currency: name });
  };

  render() {
    // Variables
    const _this = this.props._this;
    const width = _this.state.width;

    // To render on screen
    return (
      <div
        className='main-header'
        style={{
          paddingLeft: width > 767 ? width * 0.05 : 0,
          paddingRight: width > 767 ? width * 0.05 : 0,
        }}
      >
        <div className='select-language'>
          <select
            defaultValue='english'
            className='select-language-input'
            id='select-language-input'
          >
            <option value='english'>English</option>
          </select>
        </div>

        {UI.box(5)}

        <Dropdown className='select-currency-dropdown'>
          <Dropdown.Toggle variant='success' id='dropdown-currency'>
            {this.state.currency && (
              <div className='currency-select'>
                <img className='logo' src={this.checkFlag()} alt='flag logo' />
                <p>{this.state.currency}</p>
              </div>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.changeCurrency('PHP')}>
              <div className='currency-select'>
                <img className='logo' src={phLogo} alt='flag logo' />
                <p>Php</p>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <View style={{ flex: 1 }}></View>

        <span className='follow-us'>Follow us on:</span>
        {UI.box(5)}
        <FollowIcon url={'https://www.facebook.com/Traversetravelandtoursph'} icon={UI.CIRCLE_FACEBOOK} />
        {/* <FollowIcon icon={UI.CIRCLE_TWITTER} /> */}
        <FollowIcon icon={UI.CIRCLE_INSTAGRAM} />
        {/* <FollowIcon icon={UI.CIRCLE_LINKEDIN} /> */}

        <Profile login={this.login} />
      </div>
    );
  }
}

const FollowIcon = (props = { icon: '' , url: '' }) => {
  return (
    <TouchableOpacity onClick={() => { window.open(props.url, '_blank'); }} className='follow-icon'>
      <Image width={20} height={20} source={props.icon} tintColor={'white'} />
    </TouchableOpacity>
  );
};

