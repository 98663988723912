import React, { Component } from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';

// css

import './Footer.scss';
import './Header.css';
import { FaThemeisle } from 'react-icons/fa';
import { getFooter, subscribe } from '../../shared/API/request';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', msg: '', cleanup: false, footerList: [] };
  }
  emailHandler() {
    const params = { email: this.state.email };
    subscribe(params, this.callback.bind(this));
  }
  async callback(res) {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        this.setState((prev) => {
          return { ...prev, msg: data.msg, cleanup: true };
        });
      }

      if (status === 401 || status === 422) {
        this.setState((prev) => {
          return { ...prev, msg: data.msg, cleanup: true };
        });
      }
    }
  }
  componentDidMount() {
    console.log('test');
    getFooter(this.footercallback);
  }
  footercallback = async (response) => {
    const { status, data } = await response;

    if (response) {
      if (status === 200 || status === 201 || status === 202) {
      
        this.setState({
          footerList: data.data,
        });
      }

      if (status === 401 || status === 422) {
        Notification('An Error Occured', 'danger');
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.cleanup) {
      setTimeout(() => {
        this.setState((prev) => {
          return { ...prev, msg: '', cleanup: false };
        });
      }, 2000);
    }
  }
  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <div className='main-container'>
        <div
          className='footer-top'
          style={{
            paddingRight: paddingX - 5,
            paddingLeft: paddingX - 5,
          }}
        >
          {/* <View
            className='footer-content'
            style={{
              marginBottom: _this.state.isMobile ? 30 : 0,
            }}
          ></View> */}
          <div className='row'>
            <div className='col-8'>
              <span className='footer-header text-center'>What's More?</span>
              {UI.box(15)}
              <div className='row'>
                {this?.state?.footerList?.map((data) => {
                  return (
                    <div className='col-lg-4'>
                      <FooterLink
                        _this={_this}
                        onClick={() => {
                          window.open(
                            data.url,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                        text={data.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='col-lg-4'>
              <View
                className='footer-content'
                style={{
                  marginBottom: _this.state.isMobile ? 30 : 0,
                }}
              >
                <span className='footer-header'>Subscribe Now!</span>
                <span className='footer-text'>
                  Sign up and we'll send the best deals to you
                </span>
                {UI.box(20)}

                <input
                  className='email-subscription'
                  type='email'
                  placeholder={'Your email here'}
                  onChange={(e) =>
                    this.setState((prev) => {
                      return { ...prev, email: e.target.value };
                    })
                  }
                />
                {UI.box(20)}
                <span className='text-white mb-2'>
                  {this.state.msg ? this.state.msg : ''}
                </span>
                <TouchableOpacity
                  className='subscribe-btn'
                  onClick={this.emailHandler.bind(this)}
                >
                  <span className='footer-text'>Subscribe</span>
                </TouchableOpacity>
              </View>
            </div>
          </div>
          {/* <View
            className='footer-content'
            style={{
              marginBottom: _this.state.isMobile ? 30 : 0,
            }}
          >
            <span className='footer-header'>Host</span>
            {UI.box(15)}
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Host your home'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Host an Experience'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Responsible Hosting'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Refer Hosts'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Resource Centre'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Community Centre'}
            />
          </View> */}
          {/* <View
            className='footer-content'
            style={{
              marginBottom: _this.state.isMobile ? 30 : 0,
            }}
          >
            <span className='footer-header'>Support</span>
            {UI.box(15)}
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Our COVID-19 Response'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Help Centre'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Cancellation Option'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Neighbourhood Support'}
            />
            <FooterLink
              _this={_this}
              onClick={() => {}}
              text={'Trust & Safety'}
            />
          </View> */}
        </div>

        <div className='footer-bottom'>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <span className='footer-text'>Copyright @ 2021 Traverse | </span>
            {UI.box(5)}
            <TouchableOpacity>
              <span className='footer-text-blue'>Privacy Policy</span>
            </TouchableOpacity>
          </View>
        </div>
      </div>
    );
  }
}

const FooterLink = (props = { _this: null, text: '', onClick: () => {} }) => {
  return (
    <TouchableOpacity
      onClick={props.onClick}
      style={{ paddingTop: 5, paddingBottom: 5 }}
    >
      <span className='footer-text'>{props.text}</span>
    </TouchableOpacity>
  );
};
